<template>
  <div class="novel-put-client">
    <div class="novel-put-client-container margin-top-bottom-twenty">
      <div
        class="search-and-operation height-fiftyTwo margin-top-bottom-twenty border-radius-6 box-shadow-light-grey"
      >
        <el-button
          @click="refreshData"

        >
          刷新数据</el-button
        >
        <el-input class=' margin-left-twentyFour' v-model="search" placeholder="请输入公众号或者appid">
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleSearch"
          ></el-button>
        </el-input>
        <el-popover placement="right" trigger="click" v-model="isShowPopover">
          <img v-if="qrCodeSrc" class="qr-code-img" :src="qrCodeSrc" alt="" />
          <span v-else>正在获取二维码...</span>
          <el-button
            class='custom-button-margin-left'
            type="primary"
            slot="reference"
            @click="addAdvers"
            >新增服务商</el-button
          >
        </el-popover>
        <el-button
          class='custom-button-margin-left'
          @click="refreshAppIdData"
          type="warning"
          plain
        >
          刷新公众号列表</el-button
        >

        <div class="login-info" v-if="loginInfo">
          <el-tag :type="getStatus().type">
            <span class="dot" :style="getStatus().bgStyle"></span>
            <span :style="getStatus().textStyle">{{ getStatus().text }}</span>
          </el-tag>
        </div>
      </div>
      <div class="table-ad-list box-shadow-light-grey border-radius-6">
        <div class="data-overview">
          <div class="total-price">
            <p>总余额 (元)</p>
            <p>{{ total_price }}</p>
          </div>
          <div class="yesterday-consume-price">
            <p>昨日总消耗</p>
            <p>{{ total_yesterdayCost }}</p>
          </div>
          <div class="today-consume-price">
            <p>今日总消耗</p>
            <p>{{ total_todayCost }}</p>
          </div>
          <div class="all-advers">
            <p>广告主数量</p>
            <p>{{ total_advers }}</p>
          </div>
        </div>
        <div class="table-info">
          <div>
            <el-table
              :data="dataList"
              height="570px"
              @sort-change="handleSortChange"

            >
              <el-table-column label="广告主名称/ID" width="260" align="center">
                <template slot-scope="scope">
                  <div class="ad-detail">
                    <img :src="scope.row.headUrl" alt="" />
                    <div>
                      <p>{{ scope.row.mpName }}</p>
                      <p>原始ID:{{ scope.row.wxname }}</p>
                      <p>App ID:{{ scope.row.appid }}</p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="广告主类型"
                prop="adType"
                width="100"
                align="center"
              >
              </el-table-column>
              <el-table-column
                label="主题"
                prop="companyName"
                align="center"
              ></el-table-column>
              <el-table-column
                label="广告主行业"
                prop="amsCategoryname"
                align="center"
              ></el-table-column>
              <el-table-column
                label="总余额"
                prop="balance"
                width="150"
                align="center"
              ></el-table-column>
              <el-table-column label="预计消费(天)" width="100" align="center">
                <template slot-scope="scope">
                  {{ getPredictCostDays(scope.row) }}
                </template>
              </el-table-column>
              <el-table-column
                label="昨日总消耗(元)"
                prop="yesterdayCost"
                width="150"
                align="center"
                sortable
              ></el-table-column>
              <el-table-column
                label="今日总消耗(元)"
                prop="todayCost"
                width="150"
                align="center"
                sortable
              ></el-table-column>
              <!-- <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="primary" @click="goToAdPublish(scope.row)"
                  >广告投放</el-button
                >
              </template>
            </el-table-column> -->
            </el-table>
            <el-pagination
              style="padding: 20px; text-align: right"
              background
              layout="total, prev, pager, next, jumper"
              :page-size="pageSize"
              :total="total_advers"
              @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getQrCode,
  getQrCodeStatus,
  refreshChannelAD,
  getAdInfo
} from '@/api/novelPut'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'novelPutClient',
  data () {
    return {
      search: '',
      dataList: [],
      total_yesterdayCost: 0,
      total_price: 0,
      total_todayCost: 0,
      total_advers: 0,
      pageSize: 6,
      page: 1,
      qrCodeSrc: '',
      uuid: '',
      timer: null,
      isShowPopover: false,
      loginInfo: null, // 登录信息
      isCanCheckQrCodeStatus: false, // 是否可以开启监听二维码的状态
      sortType: null,
      sortName: null
    }
  },
  watch: {
    isCanCheckQrCodeStatus: {
      handler (newV) {
        // console.log(newV);
        if (newV) {
          // 检查二维码扫码状态
          this.checkQrCodeStatus()
        }
      }
    },
    isShowPopover:{
      handler(newV){
        // 当二维码图片关闭时 重置isCanCheckQrCodeStatus
        if(!newV){
          this.isCanCheckQrCodeStatus = false;
        }
      }
    }
  },
  computed: {
    ...mapState(['providerInfo'])
  },
  mounted () {
    this.initData()
  },
  methods: {
    ...mapMutations(['setProviderInfo']),
    initData () {
      this.getData()
    },
    handleSearch () {
      this.page = 1
      getAdInfo({
        search: this.search,
        pageSize: this.pageSize,
        page: this.page,
        sortType: this.sortType,
        sortName: this.sortName
      }).then(res => {
        res.channels.list.forEach(item => {
          item.headUrl = `${item.headUrl}/0`
          item.adType = '公众号'
        })
        this.dataList = res.channels.list
      })
    },
    addAdvers () {
      getQrCode().then(res => {
        this.qrCodeSrc = res.base64Code
        this.uuid = res.uuid
        this.isCanCheckQrCodeStatus = true
      })
    },
    goToAdPublish (rowInfo) {
      this.$router.push({
        name: 'DeliveryMaterialManage'
      })
    },
    getPredictCostDays (row) {
      return row.predictCostDays === -1 ? '-' : row.predictCostDays
    },
    handlePageChange (page) {
      this.page = page
      this.getData()
    },
    refreshAppIdData () {
      const loginId = this.loginInfo.id
      refreshChannelAD(loginId).then(res => {
        this.$message({
          showClose: true,
          message: '刷新成功!',
          type: 'success'
        })
      })
    },
    refreshData () {
      this.page = 1
      this.getData()
    },
    async checkQrCodeStatus () {
      this.timer = setTimeout(async () => {
        const resCode = await getQrCodeStatus(this.uuid)
        if (resCode !== '405') {
          if (this.isShowPopover) {
            this.checkQrCodeStatus()
          } else {
            clearTimeout(this.timer)
            
          }
        } else {
          this.$message({
            showClose: true,
            message: '扫码成功!',
            type: 'success'
          })
          clearTimeout(this.timer)
          this.timer = null
          this.isShowPopover = false
          this.getData()
        }
      }, 1000)
    },
    getData () {
      getAdInfo({
        search: this.search,
        pageSize: this.pageSize,
        page: this.page,
        sortType: this.sortType,
        sortName: this.sortName
      }).then(res => {
        this.loginInfo = res.loginInfo
        res.channels.list.forEach(item => {
          item.headUrl = `${item.headUrl}/0`
          item.adType = '公众号'
        })
        this.dataList = res.channels.list
        this.total_advers = res.channels.total
        this.total_yesterdayCost = res.channels.list.reduce(
          (acc, b, c) => acc + b.yesterdayCost,
          0
        )
        this.total_todayCost = res.channels.list.reduce(
          (acc, b, c) => acc + b.todayCost,
          0
        )
        this.total_price = res.channels.list.reduce(
          (acc, b, c) => acc + b.balance,
          0
        )
      })
    },
    handleSortChange (column) {
      // console.log(column);
      this.sortName = column.prop
      this.sortType = column.order === 'descending' ? 'desc' : 'asc'
      this.getData()
    },
    getStatus () {
      return this.loginInfo.status === 1
        ? {
          type: 'success',
          bgStyle: {
            borderRadius: '50%',
            background: '#38c37c',
            fontWeight: 'bold'
          },
          text: '登录正常',
          textStyle: {
            color: '#38c37c',
            fontWeight: 'bold'
          }
        }
        : {
          type: 'error',
          bgStyle: {
            borderRadius: '50%',
            background: 'red',
            fontWeight: 'bold'
          },
          text: '登录过期',
          textStyle: {
            color: 'red'
          }
        }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-popover {
  width: auto !important;
}

/deep/ .el-table__body-wrapper {
  overflow: auto !important;
}

.table-info {
  box-sizing: border-box;
  > div {
    padding: 15px;
  }
}
.login-info {
  display: flex;
  align-items: center;
  margin-left: 24px;
  span {
    margin: 0 2px;
  }
}
.dot {
  display: inline-block;
  height: 10px;
  width: 10px;
}

.qr-code-img {
  width: 200px;
  height: 200px;
}
.el-table {
  box-sizing: border-box;
}
.ad-detail {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  > img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    border-radius: 50%;
  }
  > div {
    > p {
      text-align: left;
    }
    > p:not(:nth-of-type(1)) {
      font-size: 12px;
      color: #8f9196;
    }
  }
}
.novel-put-client {
  // margin: 0 10px 10px;

  .novel-put-client-title {
    background: #fff;
    box-sizing: border-box;
    padding: 30px 30px 20px 30px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.06),
      0 1px 2px 0 rgba(37, 39, 46, 0.12), 0 0 0 1px #e8e8e8;
    h3 {
      margin-bottom: 16px;

      font-weight: normal;
    }
    p {
      font-size: 13px;
    }
  }
  .novel-put-client-container {
    .search-and-operation {
      background: #fff;
      // padding: 20px 15px;
      padding: 0 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .el-input {
        width: 300px;
      }
    }
    .table-ad-list {
      height: 760px;
      background: #fff;
      box-sizing: border-box;
      .data-overview {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 32px;
        padding: 25px;
        > div {
          width: 25%;
          height: 45px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          > p:nth-of-type(2) {
            color: #07c160;
            margin-top: 12px;
            font-size: 16px;
          }
        }
        > div:not(:nth-last-of-type(1)) {
          border-right: 1px solid #ccc;
        }
      }
    }
  }
}
</style>
